const LightboxOpen = function(){
    const html = document.getElementsByTagName('html')[0];
    
    if (!document.getElementById('lightbox')) {return false;};
    const lightbox = document.getElementById('lightbox');
    const button = lightbox.getElementsByClassName('m-lightbox__close')[0];

    window.addEventListener('keydown', function(e){
        _onkeyDown(e);
    });
    document.addEventListener('click', function(e){
        _documentOnClick(e);
    });

    document.addEventListener('touchend', function(e){
        _documentOnClick(e);
    });

    button.addEventListener('click', function(){
        _toggleLightbox();
    });

    
    const _toggleLightbox = function(){
        _clear();
        html.classList.toggle('is-lightbox-open');
    };

    const _appendContent = function(content){
        _clear();
        const wrap = lightbox.getElementsByTagName('div')[0];
        wrap.appendChild(content);

        return wrap;
    };

    const _clear = function(){
        const wrap = lightbox.getElementsByTagName('div')[0];
        wrap.innerHTML = '';
    };

    const _onkeyDown = function(e){
      if (!document.body.classList.contains('is-lightbox-open')) {return;};

      if (e.key === 'Escape') {
        html.classList.remove('is-lightbox-open');
      }
    };

    const _documentOnClick = function(e){
      if (!document.body.classList.contains('is-lightbox-open')) {return;};    
      
      if (e.target.classList.contains('m-lightbox')) {
        _clear();
        html.classList.remove('is-lightbox-open');

        e.preventDefault();
        return false;
      }
    };

    return{
        toggle: _toggleLightbox,
        append: _appendContent,
    }
}();

module.exports = LightboxOpen;