const lightbox = require('./LightboxOpen');

const LightboxVideo = function(){
    
    const _init = function(youtubeId){
        lightbox.toggle();

        _makeIframe(youtubeId);
    };


    const _makeIframe = function(id){
        const wrap = _makeWrap();
        const iframe = document.createElement('iframe');
        iframe.src = 'https://www.youtube.com/embed/'+ id +'';
        wrap.appendChild(iframe);
        lightbox.append(wrap);
    };

    const _makeWrap = function(){
        const div = document.createElement('div');
        div.classList.add('m-iframe-wrap');

        return div;
    };

    return{
        init: _init,
    }
}();

module.exports = LightboxVideo;