var FAQStickyAside = function(){
  var body;
  var aside;
  var asideContent;
  var start;
  var end;
  var content;
  var footer;
  var relatedContent;

  var _init = function(){
    body = document.body;
    content = document.getElementById('faq-results') || document.getElementById('search-results');
    aside = document.getElementById('faq-aside') || document.getElementById('search-results-aside');
    asideContent = document.querySelector('.m-faq-aside__content') || document.querySelector('.m-search-results-aside__content');
    start = _getTopOffset(content);
    relatedContent = document.querySelector('.m-related-articles');
    footer = document.querySelector('.m-footer');

    if (relatedContent != null) {
      end = _getTopOffset(relatedContent) - asideContent.clientHeight - 200;
    }else{
      end = _getTopOffset(footer) - asideContent.clientHeight - 200;
    }

    window.addEventListener('scroll', _onScroll);
    window.addEventListener('resize', debounce(_onResize, 100));
  };

  var _onScroll = function(){
    
    if (window.pageYOffset >= start - 100 && window.pageYOffset < end - 250 && !body.classList.contains('is-faq-aside-sticky')) {
      body.classList.add('is-faq-aside-sticky');

    }else if(window.pageYOffset < start - 100){
      body.classList.remove('is-faq-aside-sticky');
      body.classList.remove('is-faq-aside-bottom');
    }

    if (window.pageYOffset >= start && window.pageYOffset < end - 250 && body.classList.contains('is-faq-aside-bottom')) {
      body.classList.remove('is-faq-aside-bottom'); 
    }

    if (window.pageYOffset > end - 250) {
      if (body.classList.contains('is-faq-aside-bottom')) {return;}
      body.classList.remove('is-faq-aside-sticky'); 
      body.classList.add('is-faq-aside-bottom'); 
    }
  }

  var _onResize = function(){
    start = _getTopOffset(content);
    
    if (relatedContent != null) {
      end = _getTopOffset(relatedContent) - asideContent.clientHeight - 200;
    }else{
      end = _getTopOffset(footer) - asideContent.clientHeight - 200;
    }

    if (window.pageYOffset >= start - 100 && !body.classList.contains('visivle')) {
      body.classList.add('is-faq-aside-sticky');
    }else if(window.pageYOffset < start - 100){
      body.classList.remove('is-faq-aside-sticky');
    }
  };

  var _getTopOffset = function(el){
    var offsetTop = 0;
      do {
        if ( !isNaN( el.offsetTop ) )
        {
            offsetTop += el.offsetTop;
        }
      } while( el = el.offsetParent );
      return offsetTop;
  }

  function debounce(a,b,c){var d;return function(){var e=this,f=arguments;clearTimeout(d),d=setTimeout(function(){d=null,c||a.apply(e,f)},b),c&&!d&&a.apply(e,f)}}

  return {
    init: _init
  }
}();

if (document.getElementById('faq-aside') || document.getElementById('search-results-aside')) {
  FAQStickyAside.init();
}