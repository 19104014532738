var PageShare = function(){
  var facebookShares;
  var twitterShares;
  var linkedinShares;

  var _init = function(){
    facebookShares = document.getElementsByClassName('share-facebook');
    twitterShares = document.getElementsByClassName('share-twitter');
    linkedinShares = document.getElementsByClassName('share-linkedin');

    if (twitterShares.length) {
      [].map.call(twitterShares, function(button){
        button.addEventListener('click', _openSmallWindow);
      });
    }

    if (facebookShares.length) {
      [].map.call(facebookShares, function(button){
        button.addEventListener('click', _openSmallWindow);
      });
    }

    if (linkedinShares.length) {
      [].map.call(linkedinShares, function(button){
        button.addEventListener('click', _openSmallWindow);
      });
    }
  }

  var _openSmallWindow = function(e){
    e.preventDefault();
    var w = 900;
    var h = 500;
    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    var left = ((width / 2) - (w / 2));
    var top = ((height / 2) - (h / 2));
    
    window.open(this.getAttribute('href'), 'share', 'left=990, width=600, height=350, top='+top+', left='+left+'');

    return false;
  }

  return {
    init: _init
  }
}();

if (document.getElementsByClassName('share-facebook') || document.getElementsByClassName('share-twitter')) {
  PageShare.init();
}