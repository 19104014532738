const TweetArticle = (el) => {
    el.addEventListener('click', (e) => {
        // Avoid opening duplicate links if user clicks on anchor tag inside tweet
        if (e.target.localName !== 'a') {
            const id = e.currentTarget.getAttribute('data-id');

            window.open(`https://twitter.com/gvbnieuws/status/${id}`, '_blank');
        }
    });
};

const articles = document.querySelectorAll('.m-tweet-block');

if (articles) {
    for (let i = 0; i < articles.length; i++) {
        TweetArticle(articles[i]);
    }
}
