const getTravelStats = {
    init: (differenceInDays) => {
        const amount = differenceInDays + 1;
        getTravelStats.amount = 661000 * amount;
        /*
         * Hour amount
        */
        const amountPerHour = Math.ceil(getTravelStats.amount / 24);
        const currentDayHour = new Date().getHours();

        getTravelStats.hourAmount = amountPerHour * currentDayHour;
        

        return getTravelStats.generateHTML();
    },

    generateHTML: () => {
        const string = String(getTravelStats.hourAmount);
        const arr = string.split('');
        let html = '';
        
        for(let i = 0; i < arr.length; i++){
            const number = arr[i];
            html += '<span>'+number+'</span>';
        }

        return html;
    },
}
export default getTravelStats;