var DisplayAside = function(){
  var content;
  var aside;
  var start;
  var body;
  var end;
  var relatedArticles;
  var footer;
  var noHeaderImg;

  var _init = function(){
    content = document.getElementById('article-content');
    aside = document.getElementById('article-aside');
    start = _getTopOffset(content);
    body = document.body;
    relatedArticles = document.querySelector('.m-related-articles') || document.querySelector('.m-content-section');
    footer = document.querySelector('.m-footer');
    noHeaderImg = document.querySelector('.m-article-header--no-image') || document.querySelector('.m-interview-header--no-image');

    if (noHeaderImg !== null) {
      return false;
    }
    
    if (relatedArticles) {
      end = _getTopOffset(relatedArticles) - aside.clientHeight - 500;  
    }else{
      end = _getTopOffset(footer) - aside.clientHeight - 200;
    }

    window.addEventListener('scroll', _onScroll);
    window.addEventListener('resize', debounce(_onResize, 50));
  };


  var _onScroll = function(){
    
    if (window.pageYOffset >= start - 80 && !body.classList.contains('visible')) {
      body.classList.add('is-article-aside-visible');
    }else if(window.pageYOffset < start - 80){
      body.classList.remove('is-article-aside-visible');
    }

    if (window.pageYOffset > end) {
      body.classList.remove('is-article-aside-visible'); 
    }
  }

  var _onResize = function(){
    start = _getTopOffset(content);

    if (noHeaderImg !== null) {
      start = start + 200;
    }

    if (relatedArticles) {
      end = _getTopOffset(relatedArticles) - aside.clientHeight - 200;  
    }else{
      end = _getTopOffset(footer) - aside.clientHeight - 200
    }    

    if (window.pageYOffset >= start - 80 && !body.classList.contains('visivle')) {
      body.classList.add('is-article-aside-visible');
    }else if(window.pageYOffset < start - 80){
      body.classList.remove('is-article-aside-visible');
    }
  };

  var _getTopOffset = function(el){
    var offsetTop = 0;
      do {
        if ( !isNaN( el.offsetTop ) )
        {
            offsetTop += el.offsetTop;
        }
      } while( el = el.offsetParent );
      return offsetTop;
  }

  function debounce(a,b,c){var d;return function(){var e=this,f=arguments;clearTimeout(d),d=setTimeout(function(){d=null,c||a.apply(e,f)},b),c&&!d&&a.apply(e,f)}}


  return{ 
    init: _init
  }
}();

if (document.getElementById('article-aside')) {
  window.onload = DisplayAside.init();
}