import '../../vendor/confetti-js';

export const startConfetti = {    
    
    init: () => {
        const options = {
            "target":"confetti-canvas",
            "max":"112",
            "size":"3",
            "animate":true,
            "props":["circle","square","triangle","line"],
            "colors":[[75,223,126],[249,213,35],[0,175,237],[237,78,69]],
            "clock":"25"
        };
        
        const confettiSettings = options;
        const confetti = new ConfettiGenerator(confettiSettings);
        confetti.render();
    }
}