var ToggleNavigation = function(){
  var body;
  var button;
  var closeButton;

  var _init = function(){
    body = document.body;
    button = document.getElementById('toggle-navigation');
    closeButton = document.getElementById('close-navigation');

    button.addEventListener('click', _toggleState);
    closeButton.addEventListener('click', _removeState);
    
  };

  var _toggleState = function(){
    body.classList.toggle('is-navigation-open')
  }

  var _removeState = function(){
    body.classList.remove('is-navigation-open')
  }

  return {
    init: _init
  }
}();

if (document.getElementById('toggle-navigation')) {
  ToggleNavigation.init();
}