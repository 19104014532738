function throttle(fn, threshhold, scope) {
  threshhold || (threshhold = 250);
  var last,
      deferTimer;
  return function () {
    var context = scope || this;

    var now = +new Date,
        args = arguments;
    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}
import FAQScrollToSection from './FAQScrollToSection';

if (document.getElementById('faq-filters')) {
  const FAQRetrieveResults = function(){
    const filterList = document.querySelector('.m-faq-categories__list');
    const faqResults = document.getElementById('faq-results');
    const input = document.getElementById('search-faq');
    const form = document.getElementById('faq-search');
    let newData = [];

    form.addEventListener('submit', function(e){
      e.preventDefault();
      return false;
    });

    input.addEventListener('keyup', throttle(function(e){
      getData();
    }, 300));


    const getData = function(val){

      let search_string = input.value;

      return new Promise((reject, resolve) => {
        fetch(ajaxurl+"?action=faq_search&faq_search="+search_string, {method: 'post'})
        .then(res => res.json())
        .then(data => {
          _buildUI(data);
        })
      })
    };


    const _buildUI = function(data){
      filterList.innerHTML = '';
      faqResults.innerHTML = '';
      
      if (!data.length) {
        noResults();
        return false;
      }

      data.map(function(category){
        if (category.results.length == 0) {return;};
        _buildAside(category);
        _buildList(category);
      });
    };

    const _buildAside = function(category){
      const li = '<li><a href="#'+category.name.toLowerCase().split(' ').join('-')+'">'+category.name+'</a></li>'
      filterList.innerHTML += li;
    };

    const _buildList = function(category){
      const wrap = _createWrap(category);
      const item = _createAnchor(wrap, category);
      faqResults.appendChild(item);
      FAQScrollToSection.init();
    };

    const _createWrap = function(category){
      const div = document.createElement('div');
      div.id = category.name.toLowerCase().split(' ').join('-');
      div.classList.add('m-faq-category');
      const label = document.createElement('h3');
      label.classList.add('m-faq-main-content__title');
      label.innerHTML = category.name;

      div.appendChild(label);
      return div;
    };

    const _createAnchor = function(wrap, category){
      let div;
      category.results.map(item => {
        div = '<div class="m-faq-item"><a href="'+item.url+'" class="m-faq-item__anchor"><span class="m-faq-item__icon"><svg><use xlink:href="/content/themes/gvb/assets/svg/svg-sprite.svg#icon-chevron"></use></svg></span><h3 class="m-faq-item__title">'+item.title+'</h3></a></div>';
        wrap.innerHTML += div;
      });

      return wrap;
    };

    const noResults = function(){
      faqResults.innerHTML = "<h2>Er zijn helaas geen resultaten</h2>";
    };
  }();
}