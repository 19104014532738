// Depedant on SI Video plugin
const videos = document.querySelectorAll('.si-video');

if (videos) {
	videos.forEach( function(video) {
		const thisVideo = new SiVideo(video, {
			cookiesAccepted: function() {
				if ( Cookiebot.consent.preferences == true ) {
					return true;
				}
		
				return false;
			},
			openCookieNotice: function() {
				Cookiebot.renew();
			},
			cookiebuttons: document.querySelectorAll('.CybotCookiebotDialogBodyButton')
		});

		window.addEventListener('CookiebotOnAccept', function() {
			thisVideo.initVideo();
		});
	});
}