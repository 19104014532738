import { format, differenceInDays, differenceInSeconds, differenceInHours, getDay, getMonth } from 'date-fns';
import differenceBetweenDates from './getDifference';
import { startConfetti } from './Confetti.js';
import getTravelStats from './travelStats';

const countdown = {
    launchDate: new Date( Date.UTC( 2018, 6, 22, 5, 30, 0 ) ),

    init: () => {
        countdown.domSpans = {
            background: document.getElementById('background-holder'),
            header: {
                date: document.getElementById('current-date'),
                line: document.getElementById('innerline')
            },
            counter: {
                days: document.getElementById('countdown-days'),
                hours: document.getElementById('countdown-hours'),
                minutes: document.getElementById('countdown-minutes'),
                seconds: document.getElementById('countdown-seconds')
            },
            stats: {
                content: document.getElementById('data-container')
            }
        };

        countdown.checkDate();
        document.body.classList.add('is-loaded');
    },

    checkDate: () => {

        /*
         * If the date is lower then the launch date it calls getDifference();
         * Else display confetti();
         */

        const diff = differenceInSeconds(new Date(), countdown.launchDate);
        const oneDay = 60 * 60 * 24;

        if(diff < 0){
            countdown.getDifference(1000);
            countdown.updateDate();
            
            setInterval( countdown.updateLine(), 1000);
            countdown.updateLine();

        }else if(diff >= 0 && diff < oneDay){
            countdown.displayConfetti();
        }else{
            countdown.displayStats();
        }


        setInterval( countdown.setBackground(), oneDay);
        countdown.setBackground();

    },

    getDifference: (intervalValue) => {

        /*
         * Updates every second
         */

        const obj = differenceBetweenDates(new Date(), countdown.launchDate);
        countdown.updateDomElements(obj);

        setInterval( () => {
            const obj = differenceBetweenDates(new Date(), countdown.launchDate);
            countdown.updateDate();
            countdown.updateDomElements(obj);
        }, intervalValue);

    },

    updateDomElements: (difference) => {

        const types = ['days', 'hours', 'minutes', 'seconds'];

        /*
         * Updates the innerhtml of the dom elements
         */
        types.map((type) => {
            countdown.domSpans.counter[type].innerHTML = difference[type];
        }, countdown);

    },

    updateDate: () => {
        const monthArray = [ "januari", "februari", "maart", "april", "juni", "juli", "augustus", "september", "oktober", "november", "december" ];

        const monthIndex = getMonth(new Date());
        const currentMonth = monthArray[monthIndex - 1];
        const text = format(new Date(), 'D')+' '+ currentMonth;
        countdown.domSpans.header.date.innerHTML = text;
    },

    updateLine: () => {

        const now = new Date().getTime();
        const start = new Date( Date.UTC( 2018, 0, 1, 5, 30, 0 ) );
        const rawPercentage = Math.floor( now - start.getTime() ) / Math.floor( countdown.launchDate.getTime() - start.getTime() );
        const percentage = Math.round(rawPercentage * 100) / 100;

        countdown.domSpans.header.line.style.height = percentage * 100+'%';
        countdown.domSpans.header.date.style.top = percentage * 100+'%';

    },

    displayConfetti: () => {
        
        document.body.classList.add('is-launched');
        startConfetti.init();

        /*
         * Set interval for 24 hours to display the stats
        */
        setTimeout( () => {
            countdown.displayStats();
        }, 60 * 60 * 24);
        
    },

    displayStats: () => {
        
        /**
         * Displays stats in after screen
         */
        document.body.classList.add('is-show-data');
        
        const diff = differenceInDays(new Date(), countdown.launchDate);        
        countdown.domSpans.stats.content.innerHTML = getTravelStats.init(diff);


        setInterval(() => {
            const diff = differenceInDays(new Date(), countdown.launchDate);        
            /**
             * Returns HTML String
             */
            countdown.domSpans.stats.content.innerHTML = getTravelStats.init(diff);
            
        }, 60 * 15 * 1000);
    },

    setBackground: () => {

        /**
         * Sets background depending on weekday
         */

        const index = getDay(new Date());
        const url = '/content/themes/gvb/assets/img/countdown/background-'+index+'.jpg';
        countdown.domSpans.background.style.backgroundImage = 'url('+url+')';
    }
}


if(document.getElementById('big-count-down')){
    countdown.init();
};