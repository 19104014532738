var debounce = require('debounce');

var DisplayProgressBar = function(){
  var bar;
  var percentage;
  

  var _init = function(){
    bar = document.getElementById('progress-bar');  

    percentage = 0;
    _setStyles(percentage);
    window.addEventListener('scroll', _onScroll);
  };

  var _onScroll = function(){
    percentage = _getScrollPercent();
    _setStyles(percentage);
  }

  var _setStyles = function(val){
    bar.style.width = 'calc('+val+'% - 10px)';
  }

  function _getScrollPercent() {
    var h = document.documentElement, 
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';
    return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
  }

  return {
    init: _init
  }

}();

if (document.getElementById('article-content')) {
  DisplayProgressBar.init();
}else{
  if(document.querySelector('.m-progress-bar')){
    document.querySelector('.m-progress-bar').style.display = 'none'
  }
}