const lightbox = require('./LightboxOpen');

const LightboxGallery = function(){
    let container;
    let currentImage;
    let galleryLength;

    const _init = function(containerEl, image){
        container = containerEl;
        galleryLength = container.querySelectorAll('img').length;
        currentImage = {
            el: image,
            index: parseInt(image.dataset.index, 10)
        };

        lightbox.toggle();

        if (galleryLength > 1) {
            window.addEventListener('keyup', _handleKeyup);
        }

        _createElement(currentImage.el);
    };

    const _handleKeyup = function(e){
        
        if (e.key === 'ArrowRight') {
            _handleNextImage(currentImage.index);
        }

        if (e.key === 'ArrowLeft') {
            _handlePreviousImage(currentImage.index);
        }
    };

    const _handleNextImage = function(index){
        index = index + 1;

        if (index >= galleryLength) {
            index = 0;
        }

        //new current image
        currentImage.index = index;
        currentImage.el = container.querySelector('[data-index="'+index+'"]');
        
        _createElement(currentImage.el);
    };

    const _handlePreviousImage = function(index){
        index = index - 1;

        if (index === -1) {
            index = galleryLength - 1;
        }

        //new current image
        currentImage.index = index;
        currentImage.el = container.querySelector('[data-index="'+index+'"]');
        
        _createElement(currentImage.el);
    };

    const _createElement = function(img){
        const image = new Image();
        image.src = img.getAttribute('data-image');
        lightbox.append(image);
    };

    return{
        init: _init
    }
}();

module.exports = LightboxGallery;