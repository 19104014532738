var debounce = require('debounce');

var ToggleSearch = function(){
  var button;
  var body;
  var input;

  var _init = function(){
    button = document.getElementById('toggle-search');
    body = document.body;
    input = document.getElementById('search-blog');

    button.addEventListener('click', debounce(_onClick, 100));
    window.addEventListener('keydown', _onkeyDown);
    document.addEventListener('click', _documentOnClick);
  };

  var _onClick = function(e){
    e.preventDefault();
    
    if (body.classList.contains('is-search-active')) {
      input.blur();
    } else {
      input.focus();
    }

    _toggleClass(body, 'is-search-active');
  }

  var _onkeyDown = function(e){
    if (!document.body.classList.contains('is-search-active')) {return;};

    if (e.key === 'Escape') {
      _removeClass(body, 'is-search-active');
      input.blur();
    }
  };

  var _documentOnClick = function(e){
    if (!document.body.classList.contains('is-search-active')) {return;};    
    
    if (e.target.classList.contains('is-search-active')) {
      _removeClass(body, 'is-search-active');
      input.blur();
    }
  };

  var _toggleClass = function(el, className){
    el.classList.toggle(className);
  }

  var _removeClass = function(el, className){
    el.classList.remove(className);
  }

  return{
    init: _init
  };

}();

if(document.getElementById('toggle-search')){
  ToggleSearch.init();
}