var SetTitleSize = function(){
  var title;

  var _init = function(){

    title = {
      length: document.querySelector('.m-interview-details__title').innerHTML.length,
      el: document.querySelector('.m-interview-details__title')
    };

    if (title.length > 30) {
      _addClass(title.el, 'smaller');
    }
  }

  var _addClass = function(el, className){
    el.classList.add(className);
  }

  return {
    init: _init
  }
}();

if (document.querySelector('.m-interview-details__title')) {
  SetTitleSize.init();
}