import svg4everybody from 'svg4everybody';
svg4everybody();

import './vendor/si-video-init';

import './partials/ToggleNavigation';
import './partials/NavigationScroll';
import './partials/LazyLoad';
import './partials/DisplayProgressBar';
import './partials/DisplayAside';
import './partials/SetTitleSize';
import './partials/Page';
import './partials/PageShare';
import './partials/ToggleSearch';
import './partials/FAQStickyAside';
import './partials/FAQScrollToSection';
import './partials/FAQRetrieveResults';
import './partials/Lightbox';
import './partials/SmallCountdown';
import './partials/TweetArticle';
import './partials/TwitterFallback';
import './partials/GLightbox';

import './partials/countdown/countdown';