const NavigationScroll = function(){
  let header;
  const body = document.body;

  const _init = function(){
    header = document.getElementById('main-header');

    window.addEventListener('scroll', _handleScroll);
    window.addEventListener('touchmove', _handleScroll);
  };

  const _handleScroll = function(){
    const win = window;
    
    if (win.pageYOffset > header.clientHeight * 6 && !body.classList.contains('is-scrolled')) {
      body.classList.add('is-scrolled');
    }

    if (win.pageYOffset > header.clientHeight * 7 && !body.classList.contains('is-scrolled-2')) {
      body.classList.add('is-scrolled-2');
    }

    if (win.pageYOffset < header.clientHeight * 7 && body.classList.contains('is-scrolled-2')) {
      body.classList.remove('is-scrolled-2'); 
    }

    if (win.pageYOffset < header.clientHeight * 6 && body.classList.contains('is-scrolled')) {
      body.classList.remove('is-scrolled'); 
    }

  };

  return{
    init: _init
  }
}();

if(document.getElementById('main-header')){
  NavigationScroll.init();
}