const tweets = document.querySelectorAll('.twitter-tweet');

function iniTwitterFallback(tweet) {

	if ( Cookiebot.consent.preferences == true ) {
		return;
	}

	tweet.classList.add('m-tweet-disabled');

}

if ( tweets ) {
	tweets.forEach( function(tweet) {
		iniTwitterFallback(tweet);
	});
}