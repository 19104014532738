import Cookies from 'js-cookie';
const LightboxVideo = require('./LightboxVideo');
const LightboxGallery = require('./LightboxGallery');

const lightbox = function(){
    const cookiesAccepted = Cookies.get('gvb-accepted-cookies');
    const htmlTag = document.querySelector('html');

    const setCookie = (bool) => {
        Cookies.set(`gvb-accepted-cookies`, bool);
        htmlTag.classList.remove('is-modal-visible');
    };

    const displayModal = (obj) => {
        htmlTag.classList.add('is-modal-visible');
        const modal = document.getElementById('cookie-modal');
        const modalContent = modal.querySelector('.m-modal__content');
        const modalContentHTML = `<p>${obj.body}</p>`;
        modalContent.innerHTML = modalContentHTML;

        const acceptButton = modal.querySelector('.m-modal__primary-button');
        const rejectButton = modal.querySelector('.m-modal__secondary-button');

        acceptButton.addEventListener('click', () => {
            setCookie(true);
            LightboxVideo.init(obj.video.dataset.youtubeid);
        });

        rejectButton.addEventListener('click', () => {
            setCookie(false);
        });
    }

    const _init = function(){
        const videos = document.querySelectorAll('[data-video-lightbox]');
        [].map.call(videos, function(video){
            video.dataset.type = 'video';
            video.addEventListener('click', (event) => { handleClick(event, video) });
        });

        const galleries = document.getElementsByClassName('m-gallery');
        [].map.call(galleries, function(gallery){            
            const images = gallery.querySelectorAll('img');

            for (let i = images.length - 1; i >= 0; i--) {
                const img = images[i];
                img.dataset.index = i;
                img.dataset.type = 'galleryImage';
                img.addEventListener('click', (event)=> { handleClick(event, img) });
            }
        });        
    }

    const handleClick = function(e, item){
        if (item.dataset.type === 'video') {
            if (cookiesAccepted !== 'true') {
                displayModal({
                    body: 'Om deze video te bekijken moet u de cookies accepteren',
                    video: item,
                });
            } else {
                LightboxVideo.init(item.dataset.youtubeid);
            }
        }

        if (item.dataset.type === 'galleryImage') {
            LightboxGallery.init(item.parentNode.parentNode, item);
        }

        e.stopPropagation();
    };

    return {
        init: _init
    }
}();

if (document.getElementsByClassName('m-video-block') || document.getElementsByClassName('m-gallery')) {
    lightbox.init();
}