var imageLazyLoad = function(){
  var arr;
  
  var _init = function(){
    arr = _documentGetImages();
    _imagesApplyImage(arr);
    _addEventListeners();
  };

  var _documentGetImages = function(){
    var arr = document.getElementsByClassName('lazy-load');
    return arr;
  };

  var _imagesApplyImage = function(){
    [].map.call(arr, function(el){
      if (_imagesCheckInView(el) === true && !el.classList.contains('lazy-loaded')) {
        _lazyLoadImage(el);
      }
    });
  };

  var _imagesCheckInView = function(el){
    var rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight + el.clientHeight|| document.documentElement.clientHeight + el.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  var _lazyLoadImage = function(el){
    var image = new Image();
    
    image.onload = function(){
      if (el.tagName === "IMG") {
        el.src = image.src;
      }else if (el.tagName === "DIV" || el.tagName === "FIGURE" || el.tagName === "HEADER" || el.tagName === "SECTION"){
        el.style.backgroundImage = 'url('+image.src+')';
      }
      
      el.classList.add('lazy-loaded');
      
    };

    image.onerror = function(errer){
      el.classList.add('lazy-loaded');
      return;
    }
    image.src = el.dataset.src;
  };

  var _addEventListeners = function(){
    window.addEventListener('scroll', _imagesApplyImage, false);
  };

  return {
    init: _init
  };
}();

if (document.getElementsByClassName('lazy-load')) {
  imageLazyLoad.init()
}