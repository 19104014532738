const differenceBetweenDates = (currentTime, endTime) => {

    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;

    const now = currentTime.getTime();
    const time = endTime - now;
    
    const days = Math.floor(time / _day);
    const hours = Math.floor((time % _day) / _hour);
    const minutes = Math.floor((time % _hour) / _minute);
    const seconds = Math.floor((time % _minute) / _second);


    return { days, hours, minutes, seconds };
};

export default differenceBetweenDates;