require('smoothscroll-polyfill').polyfill();

var FAQScrollToSection = function(){
  var filterUrls;
  var filterContainer
  var filterSections;
  var activeAnchor;
  var header;

  var _init = function(){
    if (document.getElementById('faq-filters')) {
      filterUrls = document.getElementById('faq-filters').getElementsByTagName('a');
    }else if(document.getElementById('search-filters')){
      filterUrls = document.getElementById('search-filters').getElementsByTagName('a') 
    }

    filterContainer = document.getElementById('faq-results') || document.getElementById('search-results');
    filterSections = filterContainer.getElementsByClassName('m-faq-category') || filterContainer.getElementsByClassName('m-search-results-main-content__section') ;
    header = document.getElementById('main-header');

    [].map.call(filterUrls, function(anchor){
      anchor.addEventListener('click', _onAnchorClick);
    });
  };

  var _onAnchorClick = function(e){
    e.preventDefault();
    if (activeAnchor != undefined) {
      _removeActiveClass(activeAnchor);
    }
    var _this = e.currentTarget;
    activeAnchor = _this;
    _this.classList.add('active');
    var targetDiv = document.getElementById(_this.getAttribute('href').replace('#',''));
    var position = _getTopOffset(targetDiv) - header.clientHeight - 40;
    window.scroll({ top: position, left: 0, behavior: 'smooth' });
  };

  var _removeActiveClass = function(el){
    el.classList.remove('active');
  };

  var _initScrollSpy = function(){
    var spy = new ScrollSpy('#faq-overview', {
      nav: '.m-faq-categories__list > li > a',
      className: 'active'
    });
  }

  var _getTopOffset = function(el){
    var offsetTop = 0;
      do {
        if ( !isNaN( el.offsetTop ) )
        {
            offsetTop += el.offsetTop;
        }
      } while( el = el.offsetParent );
      return offsetTop;
  }

  return {
    init: _init,
  }
}();

if (document.getElementById('faq-filters') || document.getElementById('search-filters')) {
  FAQScrollToSection.init();
}

module.exports = FAQScrollToSection;