import { format } from 'date-fns';

const smallCountdown = function(){

    //22 juli 08:30 2018;
    const launchDate = new Date( Date.UTC( 2018, 6, 22, 5, 30, 0 ) );
    const currentDate = document.getElementById('current-date');
    
    const _init = function(){        
        count(launchDate);
        function count(endTime){
            const end = endTime;

            const _second = 1000;
            const _minute = _second * 60;
            const _hour = _minute * 60;
            const _day = _hour * 24;

            let timer;
            let dateTimer;

            function getDiff(){
                const now = new Date().getTime();
                const time = end - now;
                
                if (time < 0) {
                    clearInterval(timer);
                    return;
                };

                const days = Math.floor(time / _day);
                const hours = Math.floor((time % _day) / _hour);
                const minutes = Math.floor((time % _hour) / _minute);
                const seconds = Math.floor((time % _minute) / _second);

                document.getElementById('countdown-days').innerHTML = days;
                document.getElementById('countdown-hours').innerHTML = hours;
                document.getElementById('countdown-minutes').innerHTML = minutes;
                document.getElementById('countdown-seconds').innerHTML = seconds;
            };

            timer = setInterval(getDiff, 1000);

            setTimeout(() => {
                document.getElementById('lead-countdown').classList.add('visible');
            }, _second);
        };
    }
    
    return {
        init: _init
    }
}();
if(document.getElementById('lead-countdown')){
    smallCountdown.init();   
}