import GLightbox from 'glightbox';

var categories = document.querySelectorAll('.m-imagebank__category');

var glightbox = function(){

    const _init = function(){
        
        let i = 1

        categories.forEach((categories) => { 

            var box = '.glightbox-' + i;

            var lightbox = GLightbox({
                selector: box
            });

            i = i + 1;
        } );
    };

    return{
        init: _init
    }
}();

if(categories) {
    glightbox.init();
}